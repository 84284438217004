import caricon from '../imgs/caricon.png'
import utils from './utils'

import React, { Component } from 'react'

import '../css/top-nav-bar.css'

import WebsiteLogo from "../imgs/website_logo.png";

export default class TopNavBar extends Component {
  handleLogout = () => {
    utils.logout();
  }

  gotoHomePage = () => {
      let homepage_url = '#/home'
      window.location.href = homepage_url;
  }

  componentDidMount = () => {
    document.querySelectorAll('.navbar-nav-link').forEach(item => {
      item.addEventListener('click', event => {
        if (window.innerWidth < 992) {
          document.querySelector(".navbar-toggler").click();
        }
      })
    })
  }

  render() {

    return (
      <div>
        <div class="container navigation-bar">
          <div className="icon" onClick={this.gotoHomePage}>
            <img src={WebsiteLogo}  style={{height: 60 }} alt="95Carrental" />
          </div>
          <div className='login-register-block'>
            {/* 登录 ｜ 注册 */}
          </div>
        </div>
      </div>
    )
  }
}
