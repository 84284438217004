import { Component } from "react";
import MapPin from '../imgs/car_icons/MapPin@2x.png'
import Medal from '../imgs/car_icons/Medal@2x.png'
import axios from 'axios'
import utils from './utils'
import { Modal, Button, Alert } from "react-bootstrap";


export default class Order extends Component {

    constructor(props) {
        super(props);
        this.initializeOrder = this.initializeOrder.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.createCheckoutSesseion = this.createCheckoutSesseion.bind(this);
        this.triggerPayment = this.triggerPayment.bind(this);
    }
    
    state = {
        userMode: false,
        paymentDone: false,
        pickupDate: '',
        pickupTime: '',
        dropoffDate: '',
        dropoffTime: '',
        deposit: 100,
        customerName: '',
        email: '',
        wechat: '',
        telephone: '',
        contactPreference: 'EMAIL',
        remark: '',
        linkForUser: '',
        showLinkModal: false
    }

    componentWillMount = () => {
        document.title = "95租车 - 洛杉矶最大的华人中高端租车平台";
        var paths = window.location.hash.split('/')
        if (this.props.location.state) {
            this.setState({ vehicle: this.props.location.state })
        } else if (paths.length === 3) {
            var orderId = paths[2];
            axios.get(utils.getUrl(`payment/exotics-order/${orderId}/`))
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        order: response.data,
                        deposit: response.data.advancePayment,
                        pickupDate: response.data.pickupTime.split('T')[0],
                        pickupTime: response.data.pickupTime.split('T')[1].split('-')[0],
                        dropoffDate: response.data.returnTime.split('T')[0],
                        dropoffTime: response.data.returnTime.split('T')[1].split('-')[0],
                        vehicle: response.data.vehicle,
                        customerName: response.data.customerName,
                        email: response.data.customerEmail,
                        telephone: response.data.customerPhone,
                        wechat: response.data.customerWechat,
                        contactPreference: response.data.preferredContact,
                        remark: response.data.note,
                        userMode: true,
                        paymentDone: response.data.status === "ADVANCE_PAID" ? true : false
                    });
                })
                .catch(err => {
                    window.location.href = '/#/home';
                })
        } else if (paths.length === 4) {
            var orderId = paths[2];
            axios.get(utils.getUrl(`payment/exotics-order/${orderId}/`))
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        order: response.data,
                        deposit: response.data.advancePayment,
                        pickupDate: response.data.pickupTime.split('T')[0],
                        pickupTime: response.data.pickupTime.split('T')[1].split('-')[0],
                        dropoffDate: response.data.returnTime.split('T')[0],
                        dropoffTime: response.data.returnTime.split('T')[1].split('-')[0],
                        vehicle: response.data.vehicle,
                        customerName: response.data.customerName,
                        email: response.data.customerEmail,
                        telephone: response.data.customerPhone,
                        wechat: response.data.customerWechat,
                        contactPreference: response.data.preferredContact,
                        remark: response.data.note,
                        userMode: true,
                        paymentDone: response.data.status === "ADVANCE_PAID" ? true : false,
                        paymentFeedback: paths[3]
                    });
                })
                .catch(err => {
                    window.location.href = '/#/home';
                })
        } else {
            window.location.href = '/#/home';
        }
        
    }

    initializeOrder() {
        //data validation
        if (this.state.pickupDate === '' || this.state.pickupTime === '' || this.state.dropoffDate === '' || this.state.dropoffTime === '' || this.state.deposit === '') {
            alert('请填写完成租车信息')
            return
        }

        if (new Date(this.state.pickupDate + "T" + this.state.pickupTime) >= new Date(this.state.dropoffDate + "T" + this.state.dropoffTime)) {
            alert('还车时间不应早于取车时间')
            return
        }

        axios.post(utils.getUrl('payment/exotics-order/'),
            { 
                vehicle: this.state.vehicle.id,
                status: "ORDER_GENERATED",
                pickupTime: new Date(new Date(this.state.pickupDate + "T" + this.state.pickupTime) - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, -1),
                returnTime: new Date(new Date(this.state.dropoffDate + "T" + this.state.dropoffTime) - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, -1),
                advancePayment: this.state.deposit,
                customerName: this.state.customerName,
                customerPhone: this.state.telephone,
                customerEmail: this.state.email,
                customerWechat: this.state.wechat,
                preferredContact: this.state.contactPreference,
                note: this.state.remark,
                pickupLocation: 'N/A',
                returnLocation: 'N/A'
            }, 
            { headers: { 'Authorization': "Token " + localStorage.getItem('loginToken') } })
            .then(response => {
                console.log(response)
                this.setState({ 
                    order: response.data,
                    linkForUser: window.location.href+'/'+response.data.id,
                    showLinkModal: true
                })
            })
            .catch(err => { 
                console.log(err)
                alert("订单生成发生错误，请稍再在试")
            })
    }

    updateOrder() {
        let temp = axios.put(utils.getUrl(`payment/exotics-order/${this.state.order.id}/`),
            {
                customerName: this.state.customerName,
                customerPhone: this.state.telephone,
                customerEmail: this.state.email,
                customerWechat: this.state.wechat
            })
            .then(response => {
                console.log(response)
            })
    }

    createCheckoutSesseion() {
        axios.post(utils.getUrl(`payment/exotics-order/${this.state.order.id}/create_checkout_session/`))
            .then(response => {
                if (response.data.checkout_session)
                    window.open(response.data.checkout_session, "_blank")
                else alert("支付发生错误 请重试")
            })
            .catch(error => {
                alert("支付发生错误 请重试")
                console.log(error)
            })
    }

    triggerPayment() {
        // data validation
        if (this.state.customerName === "" || this.state.email === "" || this.state.wechat === "" || this.state.telephone === "") {
            alert("请补全信息")
            return
        }
        this.updateOrder()
        if (!(this.state.paymentDone || this.state.paymentFeedback === 'success')) {
            this.createCheckoutSesseion()
        }
    }


    copyToClipboard(e) {
        navigator.clipboard.writeText(this.state.linkForUser).then(() => {});
    }



    render() {
        return (
            <div className="container">
                <div className="row mb-3">
                    <div className="col-lg-8">
                        {this.state.paymentFeedback === 'success'? <Alert variant="success">定金支付成功！订单确认邮件将稍后发送至邮箱</Alert>:''}
                        {this.state.paymentFeedback === 'fail' ? <Alert variant="danger">定金支付失败！请重新尝试或联系我们</Alert>:''}                       
                        <from className="row g-3">
                            <div className="col-12 fs-6 fw-bold" style={{ textDecoration: 'underline', textDecorationColor: '#FBC403', textDecorationThickness: 2, textUnderlineOffset: 8 }}>
                                租车信息
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="date" className="form-control" id="pickupDate" placeholder="xexotics" value={this.state.pickupDate} onChange={(e)=>this.setState({pickupDate: e.target.value})} readOnly={this.state.userMode} />
                                <label for="pickupDate">取车日期</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="time" className="form-control" id="pickupTime" placeholder="xexotics" value={this.state.pickupTime} onChange={(e) => this.setState({ pickupTime: e.target.value })} readOnly={this.state.userMode} />
                                <label for="pickupTime">取车时间</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="date" className="form-control" id="dropoffDate" placeholder="xexotics" value={this.state.dropoffDate} onChange={(e) => this.setState({ dropoffDate: e.target.value })} readOnly={this.state.userMode} />
                                <label for="dropoffDate">还车日期</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="time" className="form-control" id="dropoffTime" placeholder="xexotics" value={this.state.dropoffTime} onChange={(e) => this.setState({ dropoffTime: e.target.value })} readOnly={this.state.userMode} />
                                <label for="dropoffTime">还车时间</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="number" className="form-control" id="deposit" placeholder="xexotics" value={this.state.deposit} onChange={(e) => this.setState({ deposit: e.target.value })} readOnly={this.state.userMode} />
                                <label for="deposit">定金</label>
                            </div>
                            <div className="col-12 fs-6 fw-bold" style={{ textDecoration: 'underline', textDecorationColor: '#FBC403', textDecorationThickness: 2, textUnderlineOffset: 8 }}>
                                个人信息及联系方式
                            </div>
                            <div className="col-12 form-floating mb-1">
                                <input type="text" className="form-control" id="customerName" placeholder="xexotics" value={this.state.customerName} onChange={(e) => this.setState({ customerName: e.target.value })} />
                                <label for="customerName">姓名</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="email" className="form-control" id="email" placeholder="xexotics" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                <label for="email">电子邮箱</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="text" className="form-control" id="wechat" placeholder="xexotics" value={this.state.wechat} onChange={(e) => this.setState({ wechat: e.target.value })} />
                                <label for="wechat">微信</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <input type="tel" className="form-control" id="telephone" placeholder="xexotics" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" value={this.state.telephone} onChange={(e) => this.setState({ telephone: e.target.value })} />
                                <label for="telephone">电话号码</label>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                                <select className="form-select" aria-label="Default select example" id="contactPreference" value={this.state.contactPreference} onChange={(e) => this.setState({ contactPreference: e.target.value })} >
                                    <option value="EMAIL">电子邮箱</option>
                                    <option value="WECHAT">微信</option>
                                    <option value="PHONE">电话号码</option>
                                </select>
                                <label for="contactPreference">偏好联系方式</label>
                            </div>
                            <div className="col-12 form-floating mb-1">
                                <input type="text" className="form-control" id="remark" placeholder="xexotics" value={this.state.remark} onChange={(e) => this.setState({ remark: e.target.value })} />
                                <label for="remark">备注</label>
                            </div>
                            <div>
                                <button type="submit" className="btn starter-btn mb-3" onClick={this.initializeOrder} style={{ width: '100%' }} hidden={this.state.userMode} >提交</button>
                            </div>
                            {this.state.linkForUser === '' ? '':
                                <div className="col-12 form-floating mb-5">
                                    <input type="text" className="form-control" id="remark" placeholder="xexotics" value={this.state.linkForUser} onClick={(e) => this.copyToClipboard(e)} readOnly />
                                    <label for="remark">用户链接</label>
                                </div>
                            }
                            
                        </from>
                    </div>
                    <div className="col-lg-4">
                        <div className="card h-100" >
                            {!this.state.vehicle?'':
                                <img src={this.state.vehicle.image[0].image} className="card-img-top" alt="暂无图片" />
                            }
                            <div className="card-body d-flex flex-column justify-content-between">
                                <div>
                                    {!this.state.vehicle?'':
                                        <div>
                                            <div style={{ fontSize: 22, fontWeight: 400 }}>{this.state.vehicle.make} {this.state.vehicle.model} {this.state.vehicle.year}</div>
                                            <div className="d-flex flex-row justify-content-between align-items-center mt-1" style={{ fontSize: 14, fontWeight: 300 }}>
                                                <div className="d-flex flex-row">
                                                    <div className="me-3">
                                                        <img src={MapPin} style={{ height: 20, width: 20 }} alt="MapPin" />
                                                        {this.state.vehicle.location}
                                                    </div>
                                                    <div className="me-3">
                                                        <img src={Medal} style={{ height: 20, width: 20 }} alt="Medal" />
                                                        4.9
                                                    </div>
                                                </div>

                                                <div className='d-flex flex-column align-items-end'>
                                                    <div style={{ fontSize: 14, fontWeight: 600 }}>${this.state.vehicle.price}/天</div>
                                                    {/* <div style={{ fontSize: 14, fontWeight: 300 }}>总价$???? 定金${this.state.deposit}</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row justify-content-between mt-1">
                                            <div>取车时间</div>
                                            <div>{this.state.pickupDate} {this.state.pickupTime}</div>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between mt-1">
                                            <div>还车时间</div>
                                            <div>{this.state.dropoffDate} {this.state.dropoffTime}</div>
                                        </div>
                                        <div className="d-flex flex-row justify-content-between mt-1">
                                            <div>取车/还车地点</div>
                                            <div>{this.state.vehicle?this.state.vehicle.location:''} x exotics 场地</div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                    
                                    <hr style={{ borderTop: '1px solid #bbb', margin: '10px -20px 8px' }}></hr>
                                    {this.state.paymentDone || this.state.paymentFeedback === 'success' ? <Alert variant="success">定金 ${this.state.deposit} USD 支付成功</Alert>
                                        : <div className="fs-3">定金: ${this.state.deposit} USD</div>}
                                    <button type="submit" className="btn starter-btn my-3" style={{ width: '100%' }} onClick={this.triggerPayment} disabled={!this.state.userMode} >{this.state.paymentDone || this.state.paymentFeedback === 'success' ? "更新个人信息" : "Stripe支付"} </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal size={'lg'} show={this.state.showLinkModal} onHide={() => this.setState({showLinkModal: false})}>
                    <Modal.Header>
                        <Modal.Title>订单已生成</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.linkForUser === '' ? '' :
                            <div className="col-12 form-floating mb-5">
                                <input type="text" className="form-control" id="remark" placeholder="xexotics" value={this.state.linkForUser} onClick={(e) => this.copyToClipboard(e)} readOnly />
                                <label for="remark">用户链接</label>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.copyToClipboard}>
                            复制
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}